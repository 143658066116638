import { render, staticRenderFns } from "./App.vue?vue&type=template&id=68c83534"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.7.3_webpack@5.80.0__lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.80.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports