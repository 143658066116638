import Vue from 'vue'
import store from '@/store'
import labels from '@/labels'
import { FireCrud, i18n } from 'fire-crud-lib'

const USE_EMULATOR_SUITE = location.hostname === 'localhost'
const LIVE = location.hostname.indexOf('test') === -1
const FUNCTIONS_REGION = LIVE ? 'europe-west1' : 'europe-west6'

const firebaseConfig = LIVE ? {
  apiKey: 'AIzaSyCilM7Urtd1wSzw21S33nLVKBkAfbqtDZ8',
  authDomain: 'noble-descent-263011.firebaseapp.com',
  databaseURL: 'https://noble-descent-263011.firebaseio.com',
  projectId: 'noble-descent-263011',
  storageBucket: 'noble-descent-263011.appspot.com',
  messagingSenderId: '642414255078',
  appId: '1:642414255078:web:24e268517153f12213d427',
} : {
  apiKey: "AIzaSyDv3Qayshki5APzwLsBOYVSZ_JF721Ge9k",
  authDomain: "smartrails-test.firebaseapp.com",
  projectId: "smartrails-test",
  storageBucket: "smartrails-test.appspot.com",
  messagingSenderId: "215857158662",
  appId: "1:215857158662:web:a896d9f809744e376e4187"
}

const config = {
  ...firebaseConfig,
  _fsPath: 'root',
  _requiredPerm: 'console',
  _functionsLocation: FUNCTIONS_REGION
}

if (USE_EMULATOR_SUITE) {
  config._firestoreEmulator = 5002
  config._functionsEmulator = 5001
}

Vue.use(FireCrud, { config, store, labels })

export {
  i18n
}