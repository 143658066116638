export default {
  en: {
    author_name: 'Author Name',
    author_link: 'Author Link',
    backend: 'Backend',
    collab: 'Collab Module',
    date: 'Date',
    demo: 'Demo-Account',
    finished: 'Finished',
    email: 'E-Mail',
    game: 'Games Module',
    help: 'Help',
    iframe: 'Content Module',
    langs: 'Languages',
    langs_limit: 'Langs Limit',
    live: 'Live',
    map_overlay: 'Maps Module',
    migrations: 'Migrations',
    name: 'Name',
    no: 'No',
    no_data: 'No Data',
    nr: 'Nr',
    open_backend: 'Open Backend',
    payrexx: 'Pay Module',
    vouchers: 'Pay Module - Vouchers',
    poi: 'POI',
    pois: 'POI',
    post: 'Post',
    posts: 'Posts',
    posts_limit: 'Posts Limit',
    provider: 'Provider',
    public: 'Public',
    publish: 'Publish',
    rating: 'Rating',
    rating_remarks: 'Feedbacks',
    reason: 'Reason',
    review: 'Review',
    show_ready_only: 'Show only ready',
    smartvue: 'AR Module',
    started: 'Started',
    store: 'Store Module',
    subusers: 'Sub Users',
    support: 'Selfservice',
    support_type: 'Support Type',
    tel: 'Tel',
    title: 'Title',
    tour: 'Trail',
    tours: 'Trails',
    tours_limit: 'Trails Limit',
    trashbin: 'Trash Bin',
    type: 'Type',
    users: 'Users',
    user: 'User',
    visited_posts: 'Visited Posts',
    whitelabeling: 'Whitelabeling',
    wl_logo: 'Logo',
    wl_color_primary: 'Primärfarbe',
    wl_color_secondary: 'Sekundärfarbe',
    wl_link_about: 'About (URL)',
    wl_link_support: 'Hilfe (URL)',
    wl_link_imprint: 'Impressum (URL)',
    wl_styles: 'Styles (CSS)',
    yes: 'Yes',
  },
  de: {
    author_name: 'Author Name',
    author_link: 'Author Link',
    backend: 'Backend',
    bestof_ready: 'Ready',
    bestof_tags: 'Best of Tags',
    collab: 'Collab Modul',
    date: 'Datum',
    demo: 'Demo-Account',
    finished: 'Beendet',
    email: 'E-Mail',
    game: 'Games Modul',
    help: 'Hilfe',
    iframe: 'Content Modul',
    langs: 'Sprachen',
    langs_limit: 'Anzahl Sprachen',
    live: 'Live',
    map_overlay: 'Maps Modul',
    migrations: 'Migrationen',
    name: 'Name',
    no: 'Nein',
    no_data: 'Keine Daten',
    nr: 'Nr',
    open: 'Offen',
    open_backend: 'Backend öffnen',
    payrexx: 'Pay Modul',
    vouchers: 'Pay Modul - Voucher',
    poi: 'POI',
    pois: 'POI',
    post: 'Posten',
    posts: 'Posten',
    posts_limit: 'Anzahl Posten (pro Trail)',
    provider: 'Anbieter',
    public: 'Public',
    publish: 'Publizieren',
    published: 'Publiziert',
    rating: 'Bewertung',
    rating_remarks: 'Feedbacks',
    reason: 'Begründung',
    review: 'Bemerkungen',
    show_ready_only: 'Nur Ready anzeigen',
    smartvue: 'AR Modul',
    started: 'Gestartet',
    store: 'Store Modul',
    subusers: 'Sub Users',
    support: 'Selfservice',
    support_type: 'Support-Fall',
    tel: 'Tel',
    title: 'Titel',
    tour: 'Trail',
    tours: 'Trails',
    type: 'Typ',
    tours_limit: 'Anzahl Trails',
    trashbin: 'Papierkorb',
    users: 'Accounts',
    user: 'Account',
    visited_posts: 'Besuchte Posten',
    yes: 'Ja',

    'bestof_tag_raetsel-trails': 'Rätsel-Trails',
    'bestof_tag_trails-for-families': 'Trails für Familien',
    'bestof_tag_learn-and-theme-trails': 'Lern- und Themenwege',
    'bestof_tag_velo-trails': 'Velotrails',
  }
}
