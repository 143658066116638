import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/live'
  },
  {
    path: '/live',
    name: 'Live',
    component: () => import(/* webpackChunkName: "live" */ '../views/Live.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')
  },
  {
    path: '/tours',
    name: 'Tours',
    component: () => import(/* webpackChunkName: "tours" */ '../views/Tours.vue')
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue')
  },
  {
    path: '/feedbacks',
    name: 'Feedbacks',
    component: () => import(/* webpackChunkName: "feedbacks" */ '../views/Feedbacks.vue')
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import(/* webpackChunkName: "support" */ '../views/Support.vue')
  },
  {
    path: '/trashbin',
    name: 'Trashbin',
    component: () => import(/* webpackChunkName: "trashbin" */ '../views/Trashbin.vue')
  },
  {
    path: '/migrations',
    name: 'Migrations',
    component: () => import(/* webpackChunkName: "migrations" */ '../views/Migrations.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
